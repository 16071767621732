.lint-data{
    max-width: 500px;
    display: block;
    flex-direction: column;
    padding: 10px 0px 10px 0px;
    > div{
        margin: 5px 0px 5px 0px;
    }
      .stored-file-element{
        text-decoration:underline;
      }
      .file-error-element{
        color: #F93154;
      }
}

