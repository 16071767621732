.add-building{
    max-width: 500px;
    display: block;
    flex-direction: column;
    padding: 10px 0px 10px 0px;
    > div{
        margin: 5px 0px 5px 0px;
    }
    .dropzone {
        height: 100px;
        text-align: center;
        border: 2px dashed #eeeeee;
        background-color: transparent;
        color: #bdbdbd;
      }
      .stored-file-element{
        text-decoration:underline;
      }
      .file-error-element{
        color: #F93154;
      }
}

