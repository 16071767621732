.map-container {
  height: 100%;
  position: relative;
  // z-index: 0;
  overflow: hidden;
  color: white;
  .building-name {
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-weight: bold;
    color: #222;
    font-size: 24px;;
  }
}
.map-viewer, .map-viewer canvas {
  height: calc(100vh - 58px);
  overflow: hidden;
  // width: 100vw;
  // height: 100vh;
}
.unity-ui-container {
  position: absolute;
  // z-index: 1;
  height: 100%;
  width: 100%;
}

.floor-selector-dropdown {
  top: 10px;
  left: 10px;
}
