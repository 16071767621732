.building-list{
    .add-building-button{
        display:flex;
        justify-content: flex-start;
        padding-bottom: 10px;
        padding-left: 10px;
    }
    .dropdown-toggle::after {
        content: none;
    }
}