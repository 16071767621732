.edit-poi-modal-body{
.loader-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
}
.form-feedback-error{
    color: #dc3545
}
.row{
    margin-bottom: 0.5rem;
}
.alert{
    margin-top: 1rem;
}
.success{
    margin-top: 1rem;
}

.footer{
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
}
.footer > {
    margin: 0.25rem;
}
}